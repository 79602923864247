h1 {
	font-family: 'Georgia Pro' !important;
	font-size: 50px;
	color: #000;
}

h2 {
	font-family: 'Georgia Pro' !important;
	font-size: 44px;
	color: #000;
}

h3 {
	font-family: 'Georgia Pro' !important;
	font-weight: 600;
	font-size: 38px;
	color: #000;
	margin-bottom: 20px;
}

h4 {
	font-family: 'Georgia Pro' !important;
	font-size: 22px;
	color: #000;
	margin-bottom: 15px;
}

h5 {
	font-family: 'Georgia Pro' !important;
	font-size: 18px;
	color: #000;
	margin-bottom: 10px;
}

h6 {
	font-family: 'Georgia Pro' !important;
	font-weight: 600;
	font-size: 16px;
	color: #000;
	margin-bottom: 10px;
}

p {
  font-family:  'Now' !important;
  font-size: large !important;
}

.btn {
	padding-top: 9px;
	padding-bottom: 9px;
	border-radius: 40px;
	border: 2px solid #000;
	font-weight: 600;
	color: #000;
}

.pl-10 {
  padding-left: 2rem;
}

.p-8 {
	padding: 8px;
}

.category-spacing {
  padding-left: 1rem;
}

.category-title {
	font-size: 26px;
	font-weight: 300;
}

.category-link {
	font-family:  'Now' !important;
	font-size: 18px;
	font-weight: 400;
}

.view-more {
	font-size: small;
	font-weight: 600;
}

.btn-brochure{
	border:1px solid #000;
	font-size: 16px;
	font-family:  'Now' !important;
	padding: 3%;
}

.header-nav{
	font-family:  'Now' !important;
	font-weight: 400;
	font-size: 20px;
}

#navbarExample4 .nav-item {
	padding: 5px 10px;
}

.loading-box {
	background: #fff;
}

  .banner-section {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
  }
  
  /* Banner Wrapper for Scrollable Effect */
  .banner-wrapper {
	width: 100%;
	max-width: 1920px;
	overflow-x: auto;
	white-space: nowrap;
  }
  
  /* Banner Image Styling */
  .banner-image {
	width: 100%;
	height: auto;
	max-height: 100vh;
	object-fit: cover;
  }
  
  /* Scrollable Effect for Large Screens */
  @media (min-width: 1480px) {
	.banner-wrapper {
	  overflow-x: scroll;
	}
	
	.banner-image {
	  width: 1920px; /* Ensures image remains full-size */
	  object-fit: auto;
	  max-height: 110vh;
	}
  }
  
  /* Medium Screens (900px - 1480px) */
  @media (max-width: 1480px) {
	.banner-image {
	  object-fit:fill;
	  max-height: 100vh;
	}
  }
  
  /* Small Screens (Below 900px) */
  @media (max-width: 900px) {
	.banner-image {
	  object-fit: cover;
	  max-height: 80vh;
	}
  }

.p-10 { 
  padding: 10px;
}

#enquire {
  cursor: pointer;
	color: white;
	padding: 0.5rem 1rem;
	background: black !important;
	left: 5%;
	bottom: 20%;
	font-weight: 400;
	letter-spacing: 1.2px;
  outline: 0;
  font-size: 1.1rem;
  padding: 10px 25px;
  display: inline-block;
  transform: skew(-21deg);
  font-family: 'Georgia Pro';
  position: relative;
  z-index: 0;
  border-radius: 6px;
  overflow: hidden;
}

/* Animated Border */
#enquire:before {
  content: '';
  position: absolute;
  inset: -2px;
  background: conic-gradient(
      from 0deg,
      #8B0000,  /* Dark Red */
      #FF0000,  /* Bright Red */
      #FF4D4D,  /* Light Red */
      #FFFFFF,  /* White */
      #000000,  /* Black */
      #8B0000   /* Back to Dark Red */
  );
  z-index: -1;
  border-radius: 10px;
  animation: rotate-border 8s ease-in-out infinite; /* Slower and smoother */
}

/* Button Background */
#enquire:after {
  content: '';
  position: absolute;
  inset: 2px;
  background: #111;
  z-index: -1;
  border-radius: 8px;
}

/* Slower & Smooth Clockwise Rotation */
@keyframes rotate-border {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Hover Effect */
#enquire:hover:before {
  filter: blur(3px);
  animation-duration: 10s; /* Makes it even smoother on hover */
}

/* Active Effect */
#enquire:active {
  color: white;
}

#enquire:active:after {
  background: black;
}

@keyframes slideIn {
  from {
      opacity: 0;
      transform: translateX(-100px); /* Start from the left */
  }
  to {
      opacity: 1;
      transform: translateX(0); /* End at normal position */
  }
}

.bannerheading {
	color: white;
	padding: 0.5rem 1rem;
	background: transparent !important;
	left: 4%;
	top: 30%;
	font-weight: 400;
	letter-spacing: 1px;
  font-size: 3rem !important;
  font-style: italic;
  text-shadow: 1px 1px #ff0000;
  animation: slideIn 2s ease-out forwards;
}

@media (max-width: 1024px) {
  .bannerheading {
    font-size: 2.5rem !important;
  }
  #enquire {
    bottom: 10%;
  }
}

/* Adjust font size for small screens (mobile) */
@media (max-width: 768px) {
  .bannerheading {
    font-size: 1.5rem !important;
  }
  #enquire {
    bottom: 8%;
  }
}

/* Further reduce font size for very small screens */
@media (max-width: 480px) {
  .bannerheading {
    font-size: 1.5rem !important;
  }
  #enquire {
    bottom: 4%;
  }
}

.features_room {
    padding-top: 40px;
    display: block;
    overflow: hidden
}

@media (max-width: 767px) {
    .features_room {
        padding-top:40px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .features_room {
        padding-top:0
    }
}

.features_room .rooms_here .single_rooms {
    position: relative;
    width: 50%;
    float: left
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms {
        width:100%;
        margin-bottom: 30px
    }
}

.features_room .rooms_here .single_rooms::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(to bottom, rgb(0 0 0) 3%, #000 7%);
    z-index: 1;
    opacity: .5
}

.features_room .rooms_here .single_rooms .room_thumb {
    position: relative;
    overflow: hidden
}

.features_room .rooms_here .single_rooms .room_thumb img {
    width: 100%;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -o-transition: .5s;
    transition: .5s
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    padding: 60px 60px 47px 60px
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading {
        padding:20px
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading {
        padding:20px
    }
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading span {
    font-size: 14px;
    color: #E8E8E8;
    margin-bottom: 9px;
    display: block;
    position: relative;
    z-index: 8
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
    font-size: 30px;
    color: #E8E8E8;
    position: relative;
    z-index: 8
}

@media (max-width: 767px) {
    .features_room .rooms_here .single_rooms .room_thumb .room_heading h3 {
        font-size:20px
    }
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading a {
    color: #E8E8E8;
    text-transform: capitalize;
    font-weight: 600;
    position: relative;
    z-index: 8;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden
}

.features_room .rooms_here .single_rooms .room_thumb .room_heading a:hover {
    color: #000000
}

.features_room .rooms_here .single_rooms:hover img {
    width: 100%;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.features_room .rooms_here .single_rooms:hover .room_heading a {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible
}

.line-button {
    color: #919191;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    padding-bottom: 2px;
}

.line-button::before {
    position: absolute;
    content: "";
    background: #919191;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
}

.category-button {
  border: none;
  background: none;
  outline: none; /* Removes focus outline */
  padding: 0;
  color: black; /* Default text color */
}

.category-button:focus {
  outline: none; /* Ensures no focus outline appears */
  box-shadow: none; /* Prevents any Bootstrap focus effects */
}

.category-button.active {
  color: red; /* Changes text to red when active */
}

.navbar-expand-lg {
  justify-content: center;
  width: 100%;
}

.form-control {
  outline: 0;
  border:0;
  color:#000000 !important;
}

.custom-inputclass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.7); /* Light black border */
  padding: 8px; 
  transition: border-color 0.3s ease-in-out;
}

.custom-inputclass:focus {
  border-color: rgba(0, 0, 0, 0.9); /* Darker black on focus */
}

#contactForm input::placeholder, textarea::placeholder {
font-size: 15px;
}

#contactForm label {
  font-size: 18px;
}

input[type="checkbox"].custom-checkbox {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: black;
  width: 1.16em;
  height: 1.16em;
  border: 1.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 0.15em;
  display: inline-grid;
  place-content: center;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
}

input[type="checkbox"].custom-checkbox:checked {
  border: 1.5px solid #cc1212;
}

/* The Checkmark */
input[type="checkbox"].custom-checkbox::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  background-color: #cc1212;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  display: block;
  border-radius: 0.1em;
}

/* Show checkmark when checked */
input[type="checkbox"].custom-checkbox:checked::before {
  transform: scale(1);
}

/* Align the checkboxes and labels properly */
.checkdiv {
  display: flex;
  align-items: center;
  gap: 8px; /* Add spacing between checkbox and label */
}

.checkdiv label {
  margin: 0;
  font-size: 16px;
  font-weight: 200;
  color: #000;
  cursor: pointer;
}

.form-control:focus {
	outline: 0;
  box-shadow: none;
}

.masonry-grid {
  column-count: 3; /* Adjust for responsiveness */
  column-gap: 1rem;
}

.masonry-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;
}

.masonry-item .card {
  break-inside: avoid;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .masonry-grid {
    column-count: 2;
  }
}

@media (max-width: 576px) {
  .masonry-grid {
    column-count: 1;
  }
}

.project-btn { 
  border: none;
  font-size: 16px;
  border-bottom: 1px solid black;
  font-style: italic;
  font-family: "Georgia Pro", serif;
  background: none;
  padding: 8px 12px;
  transition: all 0.3s ease-in-out;
}

.project-btn:hover {
  color: red;
  border-bottom: 1px solid red;
}

.project-btn.active {
  color: red;
  border-bottom: 1px solid red;
}

.project-btn:focus {
  outline: none;
}

.text-black{
  color: #000 !important;
}

.px-2 {
  padding: 0 1.5rem !important;
}

footer {
  background-size: cover;
}

.helpdiv{
  padding: 3rem;
  background-color: #cfcfcf;
}

/* ZigZagLayout.css */
@media (max-width: 768px) {
  .customabout .flex-row-reverse {
    flex-direction: column !important;
  }

}

.card {
  background: transparent !important;
  border-radius: 5px !important; 
  margin: 0 auto;
}

.aboutcontainer{
  min-height: 80vh;
}

.card-stack-wrapper {
  margin: 0 auto !important;
}

.card-stack {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-list {
  position: relative;
  margin: 0 auto;
  width: 600px;
}

.card-list .card img {
  transition: all 100ms ease-in-out;
  position: absolute;
  list-style: none;
  width: 600px;
  height: auto;
  text-align: center;
  background-size: cover;
}

.card img {
  width: 80%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.card-list .card:nth-child(9) {
  top: 0px;
  right: 48px;
}
.card-list .card:nth-child(8) {
  top: 10px;
  right: 42px;
}
.card-list .card:nth-child(7) {
  top: 20px;
  right: 36px;
}
.card-list .card:nth-child(6) {
  top: 30px;
  right: 30px;
}
.card-list .card:nth-child(5) {
  top: 40px;
  right: 24px;
}
.card-list .card:nth-child(4) {
  top: 50px;
  right: 18px;
}
.card-list .card:nth-child(3) {
  top: 60px;
  right: 12px;
}
.card-list .card:nth-child(2) {
  top: 70px;
  right: 6px;
}
.card-list .card:nth-child(1) {
  top: 80px;
  right: 0px;
}



/* Animations (same as yours) */
@keyframes scaleUp {
  0% {
    transform: scale(1.2) translateY(50px);
    opacity: 0;
  }
  20% {
    transform: scale(1.15) translateY(40px);
    opacity: 0.10;
  }
  40% {
    transform: scale(1.10) translateY(30px);
    opacity: 0.20;
  }
  60% {
    transform: scale(1.05) translateY(20px);
    opacity: 0.40;
  }
  80% {
    transform: scale(1.01) translateY(10px);
    opacity: 0.80;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  20% {
    transform: scale(1.01) translateY(20px);
    opacity: 0.80;
  }
  40% {
    transform: scale(1.05) translateY(40px);
    opacity: 0.40;
  }
  60% {
    transform: scale(1.10) translateY(60px);
    opacity: 0.20;
  }
  80% {
    transform: scale(1.15) translateY(80px);
    opacity: 0.10;
  }
  100% {
    transform: scale(1.2) translateY(100px);
    opacity: 0;
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1200px) {
  .card-list {
    width: 500px;
  }

  .card img {
    width: 90%;
  }

  .aboutcontainer{
    min-height: 90vh;
  }
}

@media (max-width: 992px) {
  .card-list {
    width: 400px;
  }

  .card img {
    width: 100%;
  }

  .aboutcontainer{
    min-height: 100vh;
  }

  .card-list .card:nth-child(9) { top: 0px; right: 36px; }
  .card-list .card:nth-child(8) { top: 8px; right: 32px; }
  .card-list .card:nth-child(7) { top: 16px; right: 28px; }
  .card-list .card:nth-child(6) { top: 24px; right: 24px; }
  .card-list .card:nth-child(5) { top: 32px; right: 20px; }
  .card-list .card:nth-child(4) { top: 40px; right: 16px; }
  .card-list .card:nth-child(3) { top: 48px; right: 12px; }
  .card-list .card:nth-child(2) { top: 56px; right: 6px; }
  .card-list .card:nth-child(1) { top: 64px; right: 0px; }
}

@media (max-width: 768px) {
  .card-list {
    width: 320px;
  }

  .card img {
    width: 100%;
  }

  .aboutcontainer{
    min-height: 100vh;
  }

  .card-list .card:nth-child(9) { top: 0px; right: 30px; }
  .card-list .card:nth-child(8) { top: 6px; right: 26px; }
  .card-list .card:nth-child(7) { top: 12px; right: 22px; }
  .card-list .card:nth-child(6) { top: 18px; right: 18px; }
  .card-list .card:nth-child(5) { top: 24px; right: 14px; }
  .card-list .card:nth-child(4) { top: 30px; right: 10px; }
  .card-list .card:nth-child(3) { top: 36px; right: 6px; }
  .card-list .card:nth-child(2) { top: 42px; right: 3px; }
  .card-list .card:nth-child(1) { top: 48px; right: 0px; }
}

@media (max-width: 576px) {
  .card-list {
    width: 260px;
  }

  .card img {
    width: 100%;
  }

  .aboutcontainer{
    min-height: 100vh;
  }

  .card-list .card:nth-child(9) { top: 0px; right: 24px; }
  .card-list .card:nth-child(8) { top: 5px; right: 20px; }
  .card-list .card:nth-child(7) { top: 10px; right: 16px; }
  .card-list .card:nth-child(6) { top: 15px; right: 12px; }
  .card-list .card:nth-child(5) { top: 20px; right: 9px; }
  .card-list .card:nth-child(4) { top: 25px; right: 6px; }
  .card-list .card:nth-child(3) { top: 30px; right: 3px; }
  .card-list .card:nth-child(2) { top: 35px; right: 1px; }
  .card-list .card:nth-child(1) { top: 40px; right: 0px; }
}
